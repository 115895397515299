import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "./Footer.module.css"

const Footer = (props) => {
  const handleLang = (e) => {
    document.documentElement.lang = e.target.value
    props.onChangeLang(e.target.value)
  }

  return (
    <div className={styles.container}>
      <Link to='/ko/intro'>
        <button className={styles.lang} style={{ fontFamily: "DXMSubtitlesM-KSCpc-EUC-H" }} onClick={handleLang} value="ko">KO</button>
      </Link>
      <Link to='/ja/intro'>
        <button className={styles.lang} style={{ fontFamily: "DXMSubtitlesM-KSCpc-EUC-H" }} onClick={handleLang} value="ja">JA</button>
      </Link>
      <Link to='/zh/intro'>
        <button className={styles.lang} style={{ fontFamily: "DXMSubtitlesM-KSCpc-EUC-H" }} onClick={handleLang} value="zh">ZH</button>
      </Link>
      <Link to='/en/intro'>
        <button className={styles.lang} style={{ fontFamily: "DXMSubtitlesM-KSCpc-EUC-H" }} onClick={handleLang} value="en">EN</button>
      </Link>
    </div>
  )
};

export default Footer;