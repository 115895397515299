import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Axios from 'axios';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Chip } from '@material-ui/core';

class Orders extends React.Component {
  state = {
    isLoading: true,
    movies: [],
    select: 'uncheck',
    page: 0,
    rowsPerPage: 10
  }


  getList = async () => {
    const {
      data: {
        result
      }
    } = await Axios.get("https://api.theblessedmoon.com/image/list",
      {
        params: {
          select: this.state.select,
          pageNum: this.state.page,
          pageCnt: this.state.rowsPerPage
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      result,
      isLoading: false
    })
  }

  componentDidMount() {
    setTimeout(() => this.getList(), 1000);

    this.getList()
  }

  handleChangePage = (event, newPage) => {
    this.state.page = newPage
    this.getList()
  };

  handleChangeSelect = (event) => {
    this.state.select = event.target.value
    this.getList()
  };

  handleChangeRowsPerPage = (event) => {
    this.state.rowsPerPage = event.target.value
    this.getList()
  };

  handleDetail = (id, photo) => {
    window.location.href = `#/dashboard/${id}`
    console.log(photo)
  }

  render() {
    const {
      isLoading, result, page, rowsPerPage
    } = this.state;
    
    return (
      <section className="container">
        {isLoading ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div>
              <React.Fragment>
                <TableContainer style={{ margin: '0 auto' }}>
                  <FormControl style={{ width: '200px', margin: '10px' }}>
                    <InputLabel id="select-label">select</InputLabel>
                    <Select
                      native
                      value={this.state.select}
                      onChange={this.handleChangeSelect}
                      labelId="select-label"
                      id="select"
                    >
                      <option value={'%'}>전체</option>
                      <option value={'check'}>확인</option>
                      <option value={'uncheck'}>미확인</option>
                    </Select>
                    <FormHelperText>all / check / uncheck selection</FormHelperText>
                  </FormControl>
                  <Table size="small" >
                    <TableHead>
                      <TableRow style={{ background: "#F0F0F0" }}>
                        <TableCell align="center" style={{ width: '15%'}}>ID</TableCell>
                        <TableCell align="center" style={{ width: '15%'}}>Number</TableCell>
                        <TableCell align="center" style={{ width: '20%'}}>Status</TableCell>
                        <TableCell align="center" style={{ width: '30%'}}>Photo</TableCell>
                        <TableCell align="center" style={{ width: '20%'}}>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {result['data'].map(row => (
                        <TableRow key={row.id} hover onClick={() => { this.handleDetail(row.id) }}>
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">{row.phone_number}</TableCell>
                          <TableCell align="center">
                            {row.status == "check" ?
                              (
                                <Chip
                                  size="medium"
                                  label="확인"
                                  color="primary"
                                />
                              ) : (
                                <Chip
                                  size="medium"
                                  label="미확인"
                                  color="secondary"
                                />
                              )}
                          </TableCell>
                          <TableCell align="center"><img src={row.url} style={{ width: "50px" }} alt="customer"/></TableCell>
                          <TableCell align="center">{row.created_at.slice(0,10)}<br/>{row.created_at.slice(10,20)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={result['count']['count']}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </React.Fragment>
            </div>
          )}
      </section>
    );
  }
}

export default Orders
