import React from 'react';
import Axios from 'axios'


class MakeupDetail extends React.Component {
  state = {
    isLoadingImage: true,
    isLoadingMakeup: true
  }
  makeupId = 1

  getImage = async () => {
    let id = window.location.hash.replace("#/dashboard/", "")
    // console.log(id)
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/image/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      image: result,
      isLoadingImage: false
    })
    // console.log(result)
  }

  getMakeupList = async () => {
    let id = window.location.hash.replace("#/dashboard/makeup/", "")
    // console.log(id)
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/makeup/list/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      makeup: result,
      isLoadingMakeup: false
    })
    // console.log(result)
  }

  handleChange = event => {
    // console.log(event.target.name, event.target.value)
    this.makeupId = event.target.value
  };

  handleDelete = (event) => {
    let id = window.location.hash.replace("#/dashboard/", "")
    // console.log(this.makeupId)
    this.deleteMakeup(id, "makeup", this.makeupId)
    // window.history.back()
  }

  deleteMakeup = async (id, makeup, makeupId) => {
    // console.log("patchImage")
    await Axios.delete(`https://api.theblessedmoon.com/makup/list${id}`, {

    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  componentDidMount() {
    this.getMakeupList()
    this.getImage()
  }

  render() {
    const {
      isLoadingMakeup, isLoadingImage, makeup, image
    } = this.state;
    return (
      <section className="container">
        {isLoadingImage || isLoadingMakeup ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div style={{
              'display': "flex",
              'flex-direction': "column",
              'justify-content': "center",
              'align-items': "center",
              'padding':"5%"
            }}>
              <div style={{
                'display': "flex",
                'flex-direction': "column",
                'justify-content': "center",
                'align-items': "center"
              }}>
                <div style={{
                  width: "400px",
                  height: "100%",
                  backgroundColor: "#0F0F0F",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <img src={makeup.analyticUrl} style={{ width: "100%", height: "100%"}} />
                  <img src={makeup.recommendUrl} style={{ width: "100%" ,height: "100%"}} />
                  <input value={makeup.link} style={{ width: "100%" }} />
                </div>
              </div>
              {/* <button onClick={this.handleDelete}>Delete</button> */}
            </div>
          )}
      </section>
    );
  }
}
export default MakeupDetail
