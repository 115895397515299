import React from 'react';
import Axios from 'axios'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Resizer from 'react-image-file-resizer';
import { storage } from '../../firebase'
import { v4 as uuidv4 } from 'uuid';
import Image from "../../image.js"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';


const classes = {
  productDiv: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    height: 'height',
    top: "-50px",
    left: "-35px"
  },
  productContents: {
    zIndex: 2,
    position: 'absolute',
    width: '100%',
  },
  productImg: {
    width: '480px',
    height: '270px',
  },
  productRadio: {
    display: "flex",
    flexDirection: "column",
    fontSize: '1px'
  },
};

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 50,
    height: 50,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 50,
      height: 50,
      // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    < div style={{ width: '50px', height: '50px', margin: '5px' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', zIndex: 2, top: '3px', left: '3px' }}>
          <img style={{ width: '44px', height: '44px' }} src={props.src} />
        </div>
        <div style={{ position: 'absolute', zIndex: 1, top: '-9px', left: '-9px' }}>
          <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
          />
        </div>
      </div>
    </div>
  );
}


class Product extends React.Component {
  state = {
    isLoadingImage: true,
    isLoadingProduct: true,
    concealer: {
      'image': Image.product_color_1_1,
      'value': 'concealer1'
    },
    chip: {
      'image': Image.product_color_2_1,
      'value': 'chip1'
    },
    eyeshadow: {
      'image': Image.product_color_3_1,
      'value': 'eyeshadow1'
    },
    lip: {
      'image': Image.product_color_4_1,
      'value': 'lip1'
    },
  }
  postProduct = async (id, concealer, chip, shadow, lip) => {
    // console.log("patchImage")
    await Axios.post(`https://api.theblessedmoon.com/product`, {
      "id": id,
      "concealer": concealer,
      "chip": chip,
      "shadow": shadow,
      "lip": lip
    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  patchImage = async (id, makeupId) => {
    // console.log("patchImage")
    await Axios.post(`https://api.theblessedmoon.com/image/${id}`, {
      "makeupId": makeupId
    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  postAligoTalk = async (id, receiver) => {
    // console.log("postAligoTalk")
    await Axios.post(`https://api.theblessedmoon.com/aligo/talk`, {
      "receiver": receiver,
      "id": id
    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  getImage = async () => {
    let id = window.location.hash.replace("#/dashboard/", "")
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/image/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      image: result,
      isLoadingImage: false
    })
    // console.log("getImage",result)
  }

  getProduct = async () => {
    let id = window.location.hash.replace("#/dashboard/", "")
    // console.log(id)
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/product/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })

    this.setState({
      product: result,
      isLoadingProduct: false
    })
    // console.log('result', result)
  }

  handleOnSend = (event) => {
    let id = window.location.hash.replace("#/dashboard/", "")
    let receiver = this.state.image.phone_number
    // console.log(receiver)
    // console.log("kakao",this.state.image.kakao_id)
    // console.log(this.state)
    this.postProduct(id, this.state.concealer['value'], this.state.chip['value'], this.state.eyeshadow['value'], this.state.lip['value'])
    this.patchImage(id, 1)
    this.postAligoTalk(id, receiver)
    window.history.back()
  }

  handleProductChange = event => {
    let image = ''
    switch (event.target.name) {
      case 'concealer':
        switch (event.target.value) {
          case 'concealer1':
            image = Image.product_color_1_1
            break
          case 'concealer2':
            image = Image.product_color_1_2
            break
          case 'concealer3':
            image = Image.product_color_1_3
            break
          case 'concealer4':
            image = Image.product_color_1_4
            break
        }
        // console.log(event.target.name, event.target.value)
        break
      case 'chip':
        switch (event.target.value) {
          case 'chip1':
            image = Image.product_color_2_1
            break
          case 'chip2':
            image = Image.product_color_2_2
            break
          case 'chip3':
            image = Image.product_color_2_3
            break
          case 'chip4':
            image = Image.product_color_2_4
            break
        }
        // console.log(event.target.name, event.target.value)
        break
      case 'eyeshadow':
        switch (event.target.value) {
          case 'eyeshadow1':
            image = Image.product_color_3_1
            break
          case 'eyeshadow2':
            image = Image.product_color_3_2
            break
          case 'eyeshadow3':
            image = Image.product_color_3_3
            break
          case 'eyeshadow4':
            image = Image.product_color_3_4
            break
        }
        // console.log(event.target.name, event.target.value)
        break
      case 'lip':
        switch (event.target.value) {
          case 'lip1':
            image = Image.product_color_4_1
            break
          case 'lip2':
            image = Image.product_color_4_2
            break
          case 'lip3':
            image = Image.product_color_4_3
            break
          case 'lip4':
            image = Image.product_color_4_4
            break
        }
        // console.log(event.target.name, event.target.value)
        break
    }
    this.setState({
      [event.target.name]: {
        'image': image,
        'value': event.target.value
      }
    });
  };

  componentDidMount() {
    this.getImage()
    this.getProduct()
  }

  render() {
    const {
      isLoadingImage, isLoadingProduct, image, product
    } = this.state;
    return (
      <section className="container">
        {isLoadingImage || isLoadingProduct ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div style={{
              'display': "flex",
              'flex-direction': "column",
              'justify-content': "center",
              'align-items': "center",
              'padding': "3%",
              'overflow': 'hidden'
            }}>
              <img src={image.url} alt="customer" style={{
                width: "300px",
                border: "2px solid rgba(255, 222, 222, 0.8)",
                margin: "5%"
              }} />
              <div style={{
                'display': "flex",
                'flex-direction': "column",
                'justify-content': "center",
                'align-items': "center"
              }}>
                <div style={{
                  width: "400px",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <div style={classes.productDiv}>
                    <div style={classes.productContents}><img style={classes.productImg} src={Image.product_case} alt="product_case" /></div>
                    <div style={classes.productContents}><img style={classes.productImg} src={this.state.concealer['image']} alt="recommend" /></div>
                    <div style={classes.productContents}><img style={classes.productImg} src={this.state.chip['image']} alt="recommend" /></div>
                    <div style={classes.productContents}><img style={classes.productImg} src={this.state.eyeshadow['image']} alt="recommend" /></div>
                    <div style={classes.productContents}><img style={classes.productImg} src={this.state.lip['image']} alt="recommend" /></div>
                  </div>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: 'wrap'
                }}>
                  <RadioGroup aria-label="concealer" name="concealer" defaultValue={`${product !== null ? product.concealer : 'concealer1'}`}
                    onChange={this.handleProductChange}
                    style={classes.productRadio}>
                    <div style={{ fontSize: '15px', color: '#888888' }} >컨실러</div>
                    {/* <FormLabel component="legend">컨실러</FormLabel> */}
                    <FormControlLabel value="concealer1" control={<StyledRadio src={Image.individual_color_1_1} />} />
                    <FormControlLabel value="concealer2" control={<StyledRadio src={Image.individual_color_1_2} />} />
                    <FormControlLabel value="concealer3" control={<StyledRadio src={Image.individual_color_1_3} />} />
                    <FormControlLabel value="concealer4" control={<StyledRadio src={Image.individual_color_1_4} />} />
                  </RadioGroup>
                  <RadioGroup aria-label="chip" name="chip" defaultValue={`${product !== null ? product.chip : 'chip1'}`}
                    onChange={this.handleProductChange}
                    style={classes.productRadio}>
                    <div style={{ fontSize: '15px', color: '#888888', paddingLeft: '10px' }} >칩</div>
                    {/* <FormLabel component="legend">칩</FormLabel> */}
                    <FormControlLabel value="chip1" control={<StyledRadio src={Image.individual_color_2_1} />} />
                    <FormControlLabel value="chip2" control={<StyledRadio src={Image.individual_color_2_2} />} />
                    <FormControlLabel value="chip3" control={<StyledRadio src={Image.individual_color_2_3} />} />
                    <FormControlLabel value="chip4" control={<StyledRadio src={Image.individual_color_2_4} />} />
                  </RadioGroup>
                  <RadioGroup aria-label="eyeshadow" name="eyeshadow" defaultValue={`${product !== null ? product.shadow : 'eyeshadow1'}`}
                    onChange={this.handleProductChange}
                    style={classes.productRadio}>
                    <div style={{ fontSize: '15px', color: '#888888', paddingLeft: '5px' }} >섀도</div>
                    {/* <FormLabel component="legend">섀도</FormLabel> */}
                    <FormControlLabel value="eyeshadow1" control={<StyledRadio src={Image.individual_color_3_1} />} />
                    <FormControlLabel value="eyeshadow2" control={<StyledRadio src={Image.individual_color_3_2} />} />
                    <FormControlLabel value="eyeshadow3" control={<StyledRadio src={Image.individual_color_3_3} />} />
                    <FormControlLabel value="eyeshadow4" control={<StyledRadio src={Image.individual_color_3_4} />} />
                  </RadioGroup>
                  <RadioGroup aria-label="lip" name="lip" defaultValue={`${product !== null ? product.lip : 'lip1'}`}
                    onChange={this.handleProductChange}
                    style={classes.productRadio}>
                    <div style={{ fontSize: '15px', color: '#888888', paddingLeft: '10px' }} >립</div>
                    {/* <FormLabel component="legend">립</FormLabel> */}
                    <FormControlLabel value="lip1" control={<StyledRadio src={Image.individual_color_4_1} />} />
                    <FormControlLabel value="lip2" control={<StyledRadio src={Image.individual_color_4_2} />} />
                    <FormControlLabel value="lip3" control={<StyledRadio src={Image.individual_color_4_3} />} />
                    <FormControlLabel value="lip4" control={<StyledRadio src={Image.individual_color_4_4} />} />
                  </RadioGroup>
                </div>
              </div>
              <Button
                style={{
                  borderRadius: '30px',
                  width: '100px',
                  marginTop: '10px',
                  fontSize: '100%',
                  cursor: 'pointer',
                  backgroundColor: '#f7b7bf',
                  color: '#FFF',
                  textDecoration: 'none',
                  boxShadow: 'none',
                  textTransform: 'none',
                }} onClick={this.handleOnSend} disabled={image.status === 'check' ? 'disabled' : false}>
                SEND
              </Button>
            </div>
          )}
      </section>
    );
  }
}

export default Product
