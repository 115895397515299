import React from "react"
import PropTypes from "prop-types"
import styles from "./Question.module.css"

const Question = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Qustion {props.id}.</div>
      <pre className={styles.contents}>{props.qustionText}</pre>
      <pre className={styles.answer}
        onClick={() => props.handler(props.id, 1)}
        dangerouslySetInnerHTML={{ __html: props.answerOne }} />
      <pre className={styles.answer}
        onClick={() => props.handler(props.id, 2)}
        dangerouslySetInnerHTML={{ __html: props.answerTwo }} />
    </div>
  )
};


Question.propTypes = {
  id: PropTypes.number.isRequired,
  textOne: PropTypes.string.isRequired,
  textTwo: PropTypes.string.isRequired,
  answerOne: PropTypes.string.isRequired,
  answerTwo: PropTypes.string.isRequired
}

export default Question