import React from 'react';
import Question from "../components/question/Question"
import "./Test.css"
import Image from "../image.js"
import questionsKO from "../text/questions/questionsKO.json"
import questionsZH from "../text/questions/questionsZH.json"
import questionsJA from "../text/questions/questionsJA.json"
import questionsEN from "../text/questions/questionsEN.json"

const slideWidth = -480; // slide width

class Test extends React.Component {
  //초기화
  state = {
    curIndex: 1,
    answers: Array(12).fill(0),
    questions: [],
    back: 'back',
    home: 'home',
  }
  componentDidMount = () => {
    const urlLang = document.location.hash.split('/')

    let questions = ''
    let back = ''
    let home = ''
    switch (urlLang[1]) {
      case "en":
        questions = questionsEN
        back = 'Go back'
        home = 'Back to start'
        break
      case "ko":
        questions = questionsKO
        back = '뒤로가기'
        home = '처음으로 돌아가기'
        break
      case "zh":
        questions = questionsZH
        back = '返回'
        home = '首页'
        break
      case "ja":
        questions = questionsJA
        back = '以前に戻る'
        home = '初めに戻る'
        break
    }

    this.setState({
      questions: questions,
      back: back,
      home: home
    })
  }

  //클릭에 마다 다음 질문으로 이동
  handler = (curIndex, answer) => {
    let transform, answers, slide
    transform = `translateX(${slideWidth * curIndex}px)`
    answers = this.state.answers.slice();
    for (let question in this.state.questions) {
      if (question == curIndex - 1) {
        slide = "heart"
      } else {
        slide = "slid_circle"
      }
    }
    this.setState({
      transform: transform,
      answers: answers,
      curIndex: curIndex + 1,
      slide: slide
    })
    answers[curIndex - 1] = answer - 1
    if (curIndex == 12) {
      window.sessionStorage.setItem('testResult', answers)
      this.props.history.push('./loading');
    }
  }

  handlerBack = (curIndex) => {
    let transform, slide
    if (curIndex < 0)
      return;

    transform = `translateX(${slideWidth * curIndex}px)`
    for (let question in this.state.questions) {
      if (question == curIndex - 1) {
        slide = "heart"
      } else {
        slide = "slid_circle"
      }
    }
    this.setState({
      transform: transform,
      curIndex: curIndex + 1,
      slide: slide
    })
  }

  handlerHome = (curIndex) => {
    let transform, slide
    transform = `translateX(${slideWidth * curIndex}px)`
    for (let question in this.state.questions) {
      if (question == curIndex - 1) {
        slide = "heart"
      } else {
        slide = "slid_circle"
      }
    }
    this.setState({
      transform: transform,
      curIndex: curIndex + 1,
      slide: slide
    })
  }
  render() {
    const {
      questions, curIndex
    } = this.state;
    return (
      <div className="test__container">
        <div className="test__slide-wrap">
          <div className="test__slide-box">
            <div className="test__slide-list" style={{ transform: this.state.transform }}>
              {questions.map(question => (
                <Question
                  key={question.id}
                  id={question.id}
                  qustionText={question.qustionText}
                  answerOne={question.answerOne}
                  answerTwo={question.answerTwo}
                  handler={this.handler}
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="test__pagenum" style={{ fontFamily: "DXMSubtitlesM-KSCpc-EUC-H" }}>{curIndex}/12</div>
          <div className="test__slide-list" >
            {questions.map(question => (
              <div className={question.id == this.state.curIndex ? "heart" : "test__slide-circle"} />
            ))}
          </div>
        </div>
        <div className="test__button-container">
          <div
            className="test__button"
            onClick={() => this.handlerBack(curIndex - 2)}>
            <img className="test__button-img" src={Image.backButton} alt='back' />
            <p className="test__button-p">{this.state.back}</p>
          </div>
          <div
            className="test__button"
            onClick={() => this.handlerHome(0)}>
            <p className="test__button-p">{this.state.home}</p>
          </div>
        </div>
      </div>
    )
  }
}
export default Test;