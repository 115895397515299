import React from 'react';
import "./Makeup.css"
import Image from "../image.js"
import webShare from 'react-web-share-api';
import Axios from 'axios'

class Makeup extends React.Component {
  state = {
    isLoadingImage: true
  }

  webShare = () => {
    console.log("webShare")
    if (navigator.share) {
      navigator.share({
        title: '메이크업 분석 결과',
        text: '메이크업 분석결과를 확인해주세요.',
        url: window.location.href,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('web share not supported');
    }
  };

  getImage = async () => {
    let id = window.location.hash.replace("#/makeup/", "")
    // console.log(id)
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/image/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      image: result,
      isLoadingImage: false
    })
    // console.log(result)
  }

  componentDidMount() {
    this.getImage()
  }

  render() {
    const {
      isLoadingImage, image
    } = this.state;

    let url = window.sessionStorage.getItem("firebaseUrl")

    return (
      <section className="container">
        {isLoadingImage ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div className="makeup__container">
              <div className="makeup__logosub" style={{ backgroundImage: `url(${image.url})` }} />
              <div className="tabs">
                <input type="radio" name="tabs" id="tabone" defaultChecked />
                <label className="tab_label_one" for="tabone">
                  <p style={{ fontFamily: "SsangmunDong_B", fontSize: "26px" }}>분석결과</p>
                  <p style={{ fontSize: "13px", letterSpacing: "-1px" }}>나의 메이크업 타입은?</p>
                </label>
                <div class="tab_one">
                  <img className="tab_product" src={image.analyticUrl} alt="analytic" />
                </div>
                <input type="radio" name="tabs" id="tabtwo" />
                <label className="tab_label_two" for="tabtwo">
                  <p style={{ fontFamily: "SsangmunDong_B", fontSize: "26px" }}>추천 제품</p>
                  <p style={{ fontSize: "13px", letterSpacing: "-1px" }}>이걸로 메이크업 해보세요!</p>
                </label>
                <div class="tab_two">
                  <a href={image.link}><img className="tab_product" src={image.recommendUrl} alt="recommend" /></a>
                </div>
              </div>
              <div style={{ fontSize: "11px", margin: "2%", color: "#909090" }}>
                분석 결과 or 추천 제품을 페이스 북 또는 카카오톡에 공유 하실 수 있습니다.
              </div>
              <div className="makeup__sns" onClick={this.webShare}>
                <img className="sns_image_arrow" src={Image.sns_arrow} alt="sns"/>
              </div>
            </div >
          )}
      </section>
    )
  }
}
export default Makeup;