import React from 'react';
import "./Makeup.css"
import Image from "../image.js"
import KakaoShare from '../kakao/Kakaoshare'
import Axios from 'axios'
import { TiInputChecked } from "react-icons/ti";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function TransitionUp(props) {
  return <Slide {...props} direction="Copy Link!" />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const productLink = `https://theblessedmoon.com/product/%EB%B8%94%EB%A0%88%EC%8A%A4%EB%AC%B8-%ED%82%B7-blessed-moon-kit/44/category/25/display/1/`;

class MakeupProduct extends React.Component {

  state = {
    productLine: {
      concealer1: {
        title: `17호`,
        image: Image.product_color_1_1,
        individual: Image.individual_color_1_1,
        contents: `라이트 베이지 컨실러`,
        text: `맑고 순수한, 결점 없는 당신을 더욱 더 화사하게 만들어줄 컨실러 17호!\n한톤 업 된 컬러로 화사함은 덤! 무결점 피부를 연출해 보세요.`,
        link: productLink
      },
      concealer2: {
        title: `21호`,
        image: Image.product_color_1_2,
        individual: Image.individual_color_1_2,
        contents: `미디움 베이지 컨실러`,
        text: `생기 가득하고 화사한 당신에게 어울리는 컨실러 21호!\n피부에 형광등을 켠 듯한 화사함을 더해 더욱 더 생기있는 피부를 연출해 보세요.`,
        link: productLink
      },
      concealer3: {
        title: `23호`,
        image: Image.product_color_1_3,
        individual: Image.individual_color_1_3,
        contents: `내추럴 베이지 컨실러`,
        text: `자연스러움을 추구하는 당신에게 어울리는 컨실러 23호!\n너무 밝지도, 어둡지도 않으면서 피부에 자연스럽게 스며드는 컬러로 내 피부같은 느낌을 연출해 보세요.`,
        link: productLink
      },
      concealer4: {
        title: `25호`,
        image: Image.product_color_1_4,
        individual: Image.individual_color_1_4,
        contents: `내추럴 웜 베이지 컨실러`,
        text: `건강하고 활력 넘치는 당신의 피부에 건강미를 더해줄 컨실러 25호!\n햇빛에 그을린 듯한 섹시하고 건강미 넘치는 컬러로 피부에 활기를 더해주세요.`,
        link: productLink
      },
      chip1: {
        title: `코튼칩`,
        image: Image.product_color_2_1,
        individual: Image.individual_color_2_1,
        contents: `달달한 디저트같은\n싱그럽고 산뜻한\n만다린 오렌지 핑크`,
        text: `수줍은 소녀같은 당신의 볼에 수줍음을 한 방울 더!\n달달한 디저트같은 싱그럽고 산뜻한 컬러감의 만다린 오렌지 핑크로\n쑥스러워 발그레해진 듯한 귀여운 볼을 연출해 보세요!`,
        link: productLink
      },
      chip2: {
        title: `바니칩`,
        image: Image.product_color_2_2,
        individual: Image.individual_color_2_2,
        contents: `차분하게 뭍어나는\n베이지빛 모노핑크`,
        text: `조용한 미소가 아름다운 당신에게는 부담스럽지 않은 색감의 치크가 어울리네요!\n차분하게 묻어나는 베이지빛 모노 핑크로 당신의 웃음을 더욱 더 아름답게 만들어 보세요.`,
        link: productLink
      },
      chip3: {
        title: `데이칩`,
        image: Image.product_color_2_3,
        individual: Image.individual_color_2_3,
        contents: `여리여리한 살구\n베이지톤의 색상`,
        text: `꾸민 듯 안 꾸민 듯! 자연스러운 은은한 컬러가 잘 어울리는 당신!\n여리여리한 살구 베이지톤의 색상으로 차분한 듯 은은한 생기를 불어넣어 주세요.`,
        link: productLink
      },
      chip4: {
        title: `무드칩`,
        image: Image.product_color_2_4,
        individual: Image.individual_color_2_4,
        contents: `가을 노을빛의\n우아한 로즈`,
        text: `그윽하고 우아한 분위기의 당신에게는 통통튀는 색감보다는 한 톤 다운된 차분한 컬러가 어울립니다!\n해질녘, 가을 노을빛의 우아한 로즈 컬러로 더욱 더 고혹적인 분위기를 연출해 보세요.`,
        link: productLink
      },
      eyeshadow1: {
        title: `제제&쥬빌레`,
        image: Image.product_color_3_1,
        individual: Image.individual_color_3_1,
        contents: `형광 코랄빛\n촉촉하게 물먹은 오렌지`,
        text: `생기발랄한 이미지의 당신에게는 통통튀는 색감의 컬러가 잘 어울리네요!\n화사한 형광 코랄빛 컬러와 촉촉하게 물먹은 오렌지의 조합으로 통통튀는 수채화 색감의 메이크업을 연출해 보세요!`,
        link: productLink
      },
      eyeshadow2: {
        title: `코지&베이비핑`,
        image: Image.product_color_3_2,
        individual: Image.individual_color_3_2,
        contents: `핑크 캬라멜브라운\n샴페인 로즈골드빛`,
        text: `무드있는 당신에게는 은은한 브라운 느낌이 한 방울 섞인 여리여리한 색감이 어울리네요!\n핑크 캬라멜 브라운과 샴페인 로즈골드빛의 조합으로 차분면서 무드있는 아이 메이크업을 연출해 보세요.`,
        link: productLink
      },
      eyeshadow3: {
        title: `웜&허니러버`,
        image: Image.product_color_3_3,
        individual: Image.individual_color_3_3,
        contents: `따뜻한 웜 브라운\n빈티지 골드`,
        text: `차분하고 서정적인 가을의 이미지가 떠오르는 당신에게는 브라운톤의 컬러가 잘 어울립니다.\n따뜻한 웜 브라운과 빈티지 골드의 조합으로 차분하고 분위기있는 가을 분위기의 메이크업을 연출해 보세요.`,
        link: productLink
      },
      eyeshadow4: {
        title: `쥴리&섀드나잇`,
        image: Image.product_color_3_4,
        individual: Image.individual_color_3_4,
        contents: `레드와 브라운사이\n피넛 펄화이트`,
        text: `생기 넘치는 순수한 눈망울의 당신에게는 맑으면서도 생기 넘치는 컬러가 잘 어울리네요!\n레드와 브라운 사이의 생기 넘치는 컬러와 피넛, 코코넛 브라운의 조합인 글리터 섀도우로 생기 넘치면서 초롱초롱한 아이 메이크업을 연출해 보세요.`,
        link: productLink
      },
      lip1: {
        title: `캔디빔`,
        image: Image.product_color_4_1,
        individual: Image.individual_color_4_1,
        contents: `홍시를 한입 베어 문듯한\n사랑스러운 오렌지`,
        text: `활발하면서 통통튀는 애교 만점의 당신의 성격과 꼭 닮은 오렌지 레드 컬러를 추천해 드릴게요!\n잘 익은 빨간 홍시를 한입 베어 문 듯한 사랑스러운 색감의 오렌지 레드 컬러로 통통튀는 립을 연출해 보세요.`,
        link: productLink
      },
      lip2: {
        title: `핑크넛`,
        image: Image.product_color_4_2,
        individual: Image.individual_color_4_2,
        contents: `밀도 있게 배어나는\n클래식한 로즈베이지`,
        text: `차분하고 조용한 분위기의 당신에게는 너무 부담스럽지 않은 컬러를 추천해 드릴게요!\n밀도있게 베어나는 클래식한 로즈 베이지로 우아한 듯 분위기있는 립을 연출해 보세요.`,
        link: productLink
      },
      lip3: {
        title: `체리밤`,
        image: Image.product_color_4_3,
        individual: Image.individual_color_4_3,
        contents: `데일리 립으로 제격인\n스칼레 레드`,
        text: `활발한 성격으로 모두에게 사랑받는 당신! 언제 발라도 질리지 않는 레드립을 추천해 드릴게요.\n데일리 립으로 제격인 레드의 정석 스칼레 레드로 언제 봐도 질리지 않는 당신을 닮은 싫증나지 않는 레드립을 연출해 보세요.`,
        link: productLink
      },
      lip4: {
        title: `넉아웃`,
        image: Image.product_color_4_4,
        individual: Image.individual_color_4_4,
        contents: `톤 다운된 빈티지\n플럼 핑크`,
        text: `섹시하고 그윽한 매력의 당신과 꼭 닮은 세련된 톤다운 컬러를 추천해 드릴게요.\n톤 다운된 빈티지한 플럼 핑크로 독보적이면서 섹시한 매력을 불어넣어 보세요!`,
        link: productLink
      }
    },
    isLoadingImage: true,
    isLoadingProduct: true,
    open: false,
    snackOpen:false
  }
  webShare = () => {
    console.log("webShare")
    if (navigator.share) {
      navigator.share({
        title: '메이크업 분석 결과',
        text: '메이크업 분석결과를 확인해주세요.',
        url: window.location.href,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('web share not supported');
    }
  };
  getImage = async () => {
    let id = window.location.hash.replace("#/makeup/", "")
    // console.log(id)
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/image/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      image: result,
      isLoadingImage: false
    })
    // console.log(result)
  }
  getProduct = async () => {
    let id = window.location.hash.replace("#/makeup/", "")
    // console.log(id)
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/product/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })

    this.setState({
      product: result,
      isLoadingProduct: false
    })
    console.log(result)
  }

  handleOpen = () => {
    this.setState({
      open: true
    })
  };
  handleClose = () => {
    this.setState({
      open: false
    })
  };
  handleCopy = () => {
    this.textArea.select();
    document.execCommand('copy');
    document.getElementById("copyLink").focus()
    
    this.setState({
      snackOpen: true
    })
    // this.setState({ copySuccess: 'Copied!' });
  };
  handleSnackClose = () => {
    this.setState({
      snackOpen: false
    })
  };

  componentDidMount() {
    this.getProduct()
    this.getImage()
  }

  render() {
    const {
      isLoadingImage, isLoadingProduct, image, product
    } = this.state;

    // let url = window.sessionStorage.getItem("firebaseUrl")
    // console.log(url)
    // console.log(`${this.state.productLine[product['concealer']]['image']}`)
    // console.log(product)
    return (
      <section className="container">
        {isLoadingImage || isLoadingProduct ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div className="makeup__container">
              <div className="makeup__logosub" style={{ backgroundImage: `url(${image.url})` }} />
              <div className="tabs">
                <input type="radio" name="tabs" id="tabtwo" defaultChecked />
                <label className="tab_label_two" for="tabtwo">
                  <p style={{ fontFamily: "SsangmunDong_B", fontSize: "26px" }}>추천 제품</p>
                  <p style={{ fontSize: "13px", letterSpacing: "-1px" }}>이걸로 메이크업 해보세요!</p>
                </label>
                <div class="tab_two">
                  <div className="tab_two-container" >
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                      <div className="tab_two-container_text" style={{ fontFamily: "SsangmunDong_B", fontSize: "20px" }}>
                        추천 제품 Pick ! <TiInputChecked size="40" color="#fff" />
                      </div>
                      <div className="tab_two-container_text" style={{ fontFamily: "SsangmunDong_B", fontSize: "20px", backgroundColor: "#b0adad" }} />
                    </div>
                    <div className="tab_two-product_case">
                      <div style={{
                        zIndex: 1,
                        position: 'absolute',
                        top: '-40px',
                        left: '-10px'
                      }}>
                        <img style={{
                          width: '500px',
                          height: '280px',
                        }}
                          src={Image.product_case} alt="product_case" />
                      </div>
                      <div style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '-40px',
                        left: '-10px'
                      }}>
                        <img style={{
                          width: '500px',
                          height: '280px',
                        }}
                          src={this.state.productLine[product['concealer']]['image']} alt="recommend" />
                      </div>
                      <div style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '-40px',
                        left: '-10px'
                      }}>
                        <img style={{
                          width: '500px',
                          height: '280px',
                        }}
                          src={this.state.productLine[product['chip']]['image']} alt="recommend" />
                      </div>
                      <div style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '-40px',
                        left: '-10px'
                      }}>
                        <img style={{
                          width: '500px',
                          height: '280px',
                        }}
                          src={this.state.productLine[product['shadow']]['image']} alt="recommend" />
                      </div>
                      <div style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: '-40px',
                        left: '-10px'
                      }}>
                        <img style={{
                          width: '500px',
                          height: '280px',
                        }}
                          src={this.state.productLine[product['lip']]['image']} alt="recommend" />
                      </div>
                    </div>
                    <div className="tab_two-container_row">
                      <div className="tab_two-product_container">
                        <div className="tab_two-product_contents">
                          <p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>CONCEALER</p>
                          <p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['concealer']]['title']}</p>
                          <img style={{ width: '70px', height: '70px', marginTop: '5%', marginBottom: '5%' }} src={this.state.productLine[product['concealer']]['individual']} alt="recommend" />
                          <p style={{ fontFamily: "NanumSquare", fontSize: "10px", textAlign: 'center', padding: '2%' }}>{this.state.productLine[product['concealer']]['contents']}</p>
                        </div>
                        <a href={this.state.productLine[product['concealer']]['link']}>
                          <div className="tab_two-product_link">제품 보러 가기</div>
                        </a>
                      </div>
                      <div className="tab_two-product_container">
                        <div className="tab_two-product_contents">
                          <p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>CHEEK</p>
                          <p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['chip']]['title']}</p>
                          <img style={{ width: '70px', height: '70px', marginTop: '5%', marginBottom: '5%' }} src={this.state.productLine[product['chip']]['individual']} alt="recommend" />
                          <p style={{ fontFamily: "NanumSquare", fontSize: "10px", textAlign: 'center', padding: '2%' }}>{this.state.productLine[product['chip']]['contents']}</p>
                        </div>
                        <a href={this.state.productLine[product['chip']]['link']}>
                          <div className="tab_two-product_link">제품 보러 가기</div>
                        </a>
                      </div>
                      <div className="tab_two-product_container">
                        <div className="tab_two-product_contents">
                          <p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>EYESHADOW</p>
                          <p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['shadow']]['title']}</p>
                          <img style={{ width: '70px', height: '70px', marginTop: '5%', marginBottom: '5%' }} src={this.state.productLine[product['shadow']]['individual']} alt="recommend" />
                          <p style={{ fontFamily: "NanumSquare", fontSize: "10px", textAlign: 'center', padding: '2%' }}>{this.state.productLine[product['shadow']]['contents']}</p>
                        </div>
                        <a href={this.state.productLine[product['shadow']]['link']}>
                          <div className="tab_two-product_link">제품 보러 가기</div>
                        </a>
                      </div>
                      <div className="tab_two-product_container">
                        <div className="tab_two-product_contents">
                          <p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>LIP</p>
                          <p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['lip']]['title']}</p>
                          <img style={{ width: '70px', height: '70px', marginTop: '5%', marginBottom: '5%' }} src={this.state.productLine[product['lip']]['individual']} alt="recommend" />
                          <p style={{ fontFamily: "NanumSquare", fontSize: "10px", textAlign: 'center', padding: '2%' }} >{this.state.productLine[product['lip']]['contents']}</p>
                        </div>
                        <a href={this.state.productLine[product['lip']]['link']}>
                          <div className="tab_two-product_link">제품 보러 가기</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <div className="tab_two-container_text" style={{ fontFamily: "SsangmunDong_B", fontSize: "20px" }}>
                      함께 쓰면 좋은 제품 <TiInputChecked size="40" color="#fff" />
                    </div>
                    <div className="tab_two-container_text" style={{ fontFamily: "SsangmunDong_B", fontSize: "20px", backgroundColor: "#b0adad" }} />
                  </div>
                  <div className="tab_two-container_row">
                    <a href=' https://www.theblessedmoon.com/product/%EB%9D%BC%EC%9D%B4%ED%81%AC-%EC%96%B4-%EC%84%80%EB%8F%84%EC%9A%B0-3%EC%A2%85set-like-a-shadow-3set/99/category/1/display/3/'>
                      <div className="tab_two-product_container">
                        <div className="tab_two-product_with_contents">
                          <p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "15px", marginBottom: '10px' }}>LIKE A SHADOW</p>
                          <img style={{ width: '180px', height: '180px' }} src={Image.shadow_1} alt="recommend" />
                        </div>
                        <div className="tab_two-product_link">제품 보러 가기</div>
                      </div>
                    </a>
                    <a href=' https://www.theblessedmoon.com/product/fluffy-lip-tint-5%EC%A2%85-set%EC%83%89%EC%A1%B0%ED%95%AB%ED%94%8C-%EB%A0%88%EB%93%9C%EB%A1%9C%EC%BB%AC%EB%A7%9B%EC%A7%91/83/category/1/display/3/'>
                      <div className="tab_two-product_container">
                        <div className="tab_two-product_with_contents">
                          <p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "15px", marginBottom: '10px' }}>Fluffy Lip Tint</p>
                          <img style={{ width: '180px', height: '180px' }} src={Image.tint_1} alt="recommend" />
                        </div>
                        <div className="tab_two-product_link">제품 보러 가기</div>
                      </div>
                    </a>
                  </div>
                </div>

                <input type="radio" name="tabs" id="tabone" />
                <label className="tab_label_one" for="tabone">
                  <p style={{ fontFamily: "SsangmunDong_B", fontSize: "26px" }}>분석결과</p>
                  <p style={{ fontSize: "13px", letterSpacing: "-1px" }}>나의 메이크업 타입은?</p>
                </label>
                <div class="tab_one">
                  <div className="tabone">
                    <table className="tabone_table">
                      <tr>
                        <td>
                          <img style={{ width: '70px', height: '70px' }} src={this.state.productLine[product['concealer']]['individual']} alt="recommend" />
                        </td>
                        <tr>
                          <td><p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>CONCEALER</p></td>
                          <td><p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['concealer']]['title']}</p></td>
                        </tr>
                        <p style={{ fontFamily: "NanumSquare", fontSize: "13px", padding: '1%', letterSpacing: '-0.5px' }} >{this.state.productLine[product['concealer']]['text']}</p>
                      </tr>
                      <tr>
                        <td>
                          <img style={{ width: '70px', height: '70px' }} src={this.state.productLine[product['chip']]['individual']} alt="recommend" />
                        </td>
                        <tr>
                          <td><p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>CHEEK</p></td>
                          <td><p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['chip']]['title']}</p></td>
                        </tr>
                        <p style={{ fontFamily: "NanumSquare", fontSize: "13px", padding: '1%', letterSpacing: '-0.5px' }} >{this.state.productLine[product['chip']]['text']}</p>
                      </tr>
                      <tr>
                        <td>
                          <img style={{ width: '70px', height: '70px' }} src={this.state.productLine[product['shadow']]['individual']} alt="recommend" />
                        </td>
                        <tr>
                          <td><p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>EYESHADOW</p></td>
                          <td><p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['shadow']]['title']}</p></td>
                        </tr>
                        <p style={{ fontFamily: "NanumSquare", fontSize: "13px", padding: '1%', letterSpacing: '-0.5px' }} >{this.state.productLine[product['shadow']]['text']}</p>
                      </tr>
                      <tr>
                        <td>
                          <img style={{ width: '70px', height: '70px' }} src={this.state.productLine[product['lip']]['individual']} alt="recommend" />
                        </td>
                        <tr>
                          <td><p style={{ fontFamily: "Montserrat", fontWeight: 'bold', fontSize: "14px", letterSpacing: '-0.5px' }}>LIP</p></td>
                          <td><p style={{ fontFamily: "NanumSquare", fontWeight: 'bold', fontSize: "14px" }}>{this.state.productLine[product['lip']]['title']}</p></td>
                        </tr>
                        <p style={{ fontFamily: "NanumSquare", fontSize: "13px", padding: '1%', letterSpacing: '-0.5px' }} >{this.state.productLine[product['lip']]['text']}</p>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div style={{ fontSize: "11px", margin: "1.5%", color: "#909090" }}>
                분석 결과 or 추천 제품을 페이스 북 또는 카카오톡에 공유 하실 수 있습니다.
              </div>
              <div className="makeup__sns" onClick={this.handleOpen}>
                <img className="sns_image_arrow" src={Image.sns_arrow} alt="sns" />
              </div>
              <Dialog aria-labelledby="customized-dialog-title" open={this.state.open}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                  Share
                </DialogTitle>
                <DialogContent dividers>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "400px",
                    height: "50px",
                    border: "1px solid #BBBBBB",
                    margin: "10px",
                    borderRadius: "10px"
                  }}>
                    <KakaoShare />
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    margin: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    backgroundColor: "#EEEEEE",
                  }}>
                    <TextField
                      margin="dense"
                      id="URL"
                      label="URL"
                      type="URL"
                      fullWidth
                      defaultValue={window.location.href}
                      style={{ width: "70%", marginRight: "5%" }}
                    />                    
                    <form style={{width:"1px",height:"1px",overflow:"hidden"}}>
                      <textarea
                        ref={(textarea) => this.textArea = textarea
                        }
                        value={window.location.href}
                      />
                    </form>
                    <Button color="primary"
                      id="copyLink"
                      style={{
                        width: "30%",
                        height: "40px",
                        border: "1px solid #BBBBBB",
                        borderRadius: "10px",
                        color: "#000"
                      }}
                      onClick={this.handleCopy}
                      >
                      Copy Link
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical:`bottom`, horizontal:`center` }}
                open={this.state.snackOpen}
                onClose={this.handleSnackClose}
                message="Copy Link"
                key={'bottom' + 'center'}
              />
            </div >
          )
        }
      </section>
    )
  }
}
export default MakeupProduct;