import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://theblessedmoon.com/">
        Blessed Moon
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    background: '#f7b7bf',
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginForm({ authenticated, login, location }) {
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const classes = useStyles();

  const handleClick = () => {
    try {
      login({ id, password })
    } catch (e) {
      alert("Failed to login")
      setId("")
      setPassword("")
    }
  }

  const handleCheck = () => {
    var loginData = JSON.parse(window.localStorage.getItem('login'))
    var currentTime = new Date()

    if(loginData === null){
      return 
    }else if (loginData.login === "login" && currentTime <= loginData.time + 86400000) {
      var id = loginData.id, password = loginData.password
      try {
        login({ id, password })
      } catch (e) {
        window.localStorage.removeItem('login')
        setId("")
        setPassword("")
      }
    }else{
      window.localStorage.removeItem('login')
    }
  }

  handleCheck()
  const { from } = location.state || { from: { pathname: "/dashboard" } }
  if (authenticated)
    return (<Redirect to={from} />)

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Admin_Sign_in
        </Typography>
          <form className={classes.form} noValidate>
            <TextField
              value={id}
              onChange={({ target: { value } }) => setId(value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="id"
              label="ID"
              name="id"
              autoComplete="id"
              autoFocus
            />
            <TextField
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={handleClick}
            >
              Sign In
          </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}

export default LoginForm