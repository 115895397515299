import React from 'react';
import Axios from 'axios'
import Resizer from 'react-image-file-resizer';
import { storage } from '../../firebase'
import { v4 as uuidv4 } from 'uuid';

class MakeupAdd extends React.Component {
  state = {
    analyticUrl: null,
    recommendUrl: null,
    name: null,
    link: null,
  }

  postMakeup = async (name, analyticUrl, recommendUrl, link) => {
    await Axios.post('https://api.theblessedmoon.com/makeup', {
      "name": name,
      "analyticUrl": analyticUrl,
      "recommendUrl": recommendUrl,
      "link": link
    }).then(function (response) {
      // console.log(response)
    })
      .catch(function (error) {
        // console.log(error);
      });
  }

  resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });

  handleUrlChange = async (e) => {
    if (e.target.files[0]) {
      let name = e.target.name
      let resize = await this.resizeFile(e.target.files[0])
      this.setState({
        [name]: resize
      });
    }
  };

  handleInputChange = (e) => {
    // console.log(e.target.value)
    // let url = e.target.value.replace(/(http(s)?:\/\/)/g,'');
    let url = e.target.value.replace(/(http(s)?:\/\/)/g,'').replace(/(http(s)?:\/)/g,'');

    e.target.value = `https://${url}`

    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleUrlChange = (e) => {
    // console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleUpload = async (e) => {
    let name = this.state.name, analyticUrl = this.state.analyticUrl, recommendUrl = this.state.recommendUrl, link = this.state.link
    let attachmentAnalyticUrl = "";
    let attachmentRecommendUrl = "";
    let message = ""

    if (name == null || analyticUrl == null || recommendUrl == null || link == null) {
      analyticUrl == null ? message = `analyticUrl ${message}` : message = message
      recommendUrl == null ? message = `recommendUrl ${message}` : message = message
      link == null ? message = `link ${message}` : message = message
      name == null ? message = `name ${message}` : message = message
      alert(`${message} is null`)
      return;
    } else {
      let attachmentRef, response
      attachmentRef = storage.ref().child(`makeup/${uuidv4()}`);
      response = await attachmentRef.putString(analyticUrl, "data_url");
      attachmentAnalyticUrl = await response.ref.getDownloadURL();

      attachmentRef = storage.ref().child(`makeup/${uuidv4()}`);
      response = await attachmentRef.putString(recommendUrl, "data_url");
      attachmentRecommendUrl = await response.ref.getDownloadURL();

      this.postMakeup(name, attachmentAnalyticUrl, attachmentRecommendUrl, link)
      window.location.href = `#/dashboard/makeup`
    }
  };

  render() {
    const {
      analyticUrl, recommendUrl, link, name
    } = this.state;

    return (
      <div style={{
        'display': "flex",
        'flex-direction': "column",
        'justify-content': "center",
        'align-items': "center",
        'padding':"3%"
      }}>
        <div style={{
          'display': "flex",
          'flex-direction': "column",
          'justify-content': "center",
          'align-items': "center"
        }}>
          <div style={{
            width: "400px",
            backgroundColor: "#0F0F0F",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <img src={analyticUrl || "http://via.placeholder.com/500"} alt="analytic" style={{ width: "100%",height: "100%" }} />
            <img src={recommendUrl || "http://via.placeholder.com/500"} alt="recommend" style={{ width: "100%",height: "100%" }} />
          </div>
          <input placeholder="Name" name="name" value={name} onChange={this.handleInputChange} style={{ width: "400px", margin: "1%" }} />
          <input placeholder="Link" name="link" value={link} onChange={this.handleInputChange} style={{ width: "400px", margin: "1%" }} />
          <input type="file" name="analyticUrl" onChange={this.handleUrlChange} style={{ width: "400px", margin: "1%" }} />
          <input type="file" name="recommendUrl" onChange={this.handleUrlChange} style={{ width: "400px", margin: "1%" }} />
          <input type="submit" onClick={this.handleUpload} />
        </div>
      </div>

    );
  }
}
export default MakeupAdd
