import firebase from "firebase/app"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCaHuXZg6FWsSQ6fMqPDwl8_LXlgDP6r3c",
  authDomain: "mbti-blessedmoon.firebaseapp.com",
  databaseURL: "https://mbti-blessedmoon.firebaseio.com",
  projectId: "mbti-blessedmoon",
  storageBucket: "mbti-blessedmoon.appspot.com",
  messagingSenderId: "828722747491",
  appId: "1:828722747491:web:8383a8d131c6ba30b30056",
  measurementId: "G-SSPNW98W71"
  // apiKey: "AIzaSyCQ7eugCG8R_yV3wIYDEOrsWCCBygNhcN8",
  // authDomain: "mock-bless.firebaseapp.com",
  // databaseURL: "https://mock-bless.firebaseio.com",
  // projectId: "mock-bless",
  // storageBucket: "mock-bless.appspot.com",
  // messagingSenderId: "99866823587",
  // appId: "1:99866823587:web:b7280badba8524a3c62816",
  // measurementId: "G-35Q7JNM21E"
};

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage();

export { storage, firebase as default };