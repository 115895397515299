import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navigation.module.css";


function Navigation() {
  return <div className={styles.nav}>
      <Link to="/intro">intro</Link>
      <Link to="/test">test</Link>
      <Link to="/loading">loading</Link>
      <Link to="/result/intj">result</Link>
      <Link to="/makeup/20">makeup</Link>
      <Link to="/makeup2/61">makeup2</Link>
      <Link to="/upload">upload</Link>
      <Link to="/uploading">uploading</Link>
      <Link to="/dashboard">dashboard</Link>
  </div>
}

export default Navigation;