import React, { useEffect, useState } from 'react';
import styles from "./Intro.module.css"
import Image from "../../image"
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import mainKO from "../../text/main/mainKO.json"
import mainZH from "../../text/main/mainZH.json"
import mainJA from "../../text/main/mainJA.json"
import mainEN from "../../text/main/mainEN.json"


const Intro = (props) => {
  const [lang, setLang] = useState('')
  const urlLang = document.location.hash.split('/')
  //Mbti admin 주소 확인
  if (window.location.hostname == `mbti-admin.theblessedmoon.com`)
    window.location.href = `#/login`

  useEffect(() => {
    switch (urlLang[1]) {
      case "en":
        setLang(mainEN)
        break
      case "ko":
        setLang(mainKO)
        break
      case "zh":
        setLang(mainZH)
        break
      case "ja":
        setLang(mainJA)
        break
    }
  });

  const onChangeLang = (lang) =>{
    console.log('a')
    props.onChangeLang(lang)
  }

  return (
    <div className={styles.container} >
      <div className={styles.title}>{lang.title}</div>
      <div className={styles.subtitle}>{lang.subtitle1} <span className={styles.pink}> {lang.subtitle2}</span>{lang.subtitle3}</div>
      <div>
        <img className={styles.logosub} src={Image.intro_logo} alt="sublogo" />
      </div>
      <div className={styles.contents}>{lang.contents}</div>
      <div className={styles.subcontents} >{lang.subcontents}</div>
      <Link to={`/${urlLang[1]}/test`} className={styles.button}>{lang.button}</Link>
      <Footer onChangeLang={onChangeLang}/>
    </div>
  )
}
export default Intro;