import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Axios from 'axios';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

class MakeupList extends React.Component {
  state = {
    isLoading: true,
    movies: [],
    select: null,
    page: 0,
    rowsPerPage: 10
  }


  getMakeupList = async () => {
    const {
      data: {
        result
      }
    } = await Axios.get("https://api.theblessedmoon.com/makeup/list",
      {
        params: {
          pageNum: this.state.page,
          pageCnt: this.state.rowsPerPage
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      result,
      isLoading: false
    })
  }

  componentDidMount() {
    setTimeout(() => this.getMakeupList(), 1000);
    this.getMakeupList()
  }

  handleChangePage = (event, newPage) => {
    this.state.page = newPage
    this.getMakeupList()
  };


  handleChangeSelect = (event) => {
    this.state.select = event.target.value
    this.getMakeupList()
  };

  handleChangeRowsPerPage = (event) => {
    this.state.rowsPerPage = event.target.value
    this.getMakeupList()
  };

  handleDetail = (id, photo) => {
    window.location.href = `#/dashboard/makeup/${id}`
  }
  handleAdd = () => {
    window.location.href = `#/dashboard/makeup/add`
  }
  render() {
    const {
      isLoading, result, page, rowsPerPage
    } = this.state;
    return (
      <section className="container">
        {isLoading ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div>
              <React.Fragment>
                <Button
                  style={{ background: '#F0F0F0', width: '80px', margin: '1%', fontFamily: "sans-serif" }}
                  onClick={this.handleAdd}
                >
                  <AddIcon /> Add
                </Button>
                <TableContainer>
                  <Table size="small" >
                    <TableHead>
                      <TableRow style={{ background: "#F0F0F0" }}>
                        <TableCell align="center" style={{ width: '15%' }}>Num</TableCell>
                        <TableCell align="center" style={{ width: '15%' }}>Name</TableCell>
                        <TableCell align="center" style={{ width: '35%' }}>Analytic</TableCell>
                        <TableCell align="center" style={{ width: '35%' }}>Recommend</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {result['data'].map(row => (
                        <TableRow key={row.id} hover onClick={() => { this.handleDetail(row.id) }}>
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center"><img src={row.analyticUrl} style={{ width: "50%" }} alt="analytic" /></TableCell>
                          <TableCell align="center"><img src={row.recommendUrl} style={{ width: "50%" }} alt="recommend" /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={result['count']['count']}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </React.Fragment>
            </div>
          )}
      </section>
    );
  }
}

export default MakeupList
