import React, { useEffect } from 'react'
import Image from "../image.js"

const REACT_APP_KAKAO_KEY = 'c99dbd2b0e53f9e3c1e2549645a08540'
const REACT_FETCH_URL = window.location.href

const KakaoShare = () => {
  useEffect(() => {
    createKakaoButton()
    console.log('useEffect')
  }, [])
  const createKakaoButton = () => {
    // kakao sdk script이 정상적으로 불러와졌으면 window.Kakao로 접근이 가능합니다
    // console.log('create')
    if (window.Kakao) {
      const kakao = window.Kakao
      // console.log('Kakao')
      // 중복 initialization 방지
      console.log(kakao.isInitialized())
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        // console.log('init')
        kakao.init(`c99dbd2b0e53f9e3c1e2549645a08540`)
      }
      kakao.Link.createDefaultButton({
        // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
        container: '.kakao-link-btn',
        objectType: 'feed',
        content: {
          title: '블레스문 썸 추진력 테스트',
          description: '#MBTI #썸 능력 #분위기 #메이크업',
          imageUrl: 'https://firebasestorage.googleapis.com/v0/b/mbti-blessedmoon.appspot.com/o/app%2FKakaoTalk_Photo_2020-10-23-12-01-14.jpeg?alt=media&token=a9f7f561-0f15-480b-a3a4-ae3ac39bd7cd',
          link: {
            mobileWebUrl: 'https://mbti.theblessedmoon.com/',
            webUrl: 'https://mbti.theblessedmoon.com/',
          },
        },
        buttons: [
          {
            title: '테스트 하기',
            link: {
              mobileWebUrl: 'https://mbti.theblessedmoon.com/',
              webUrl: 'https://mbti.theblessedmoon.com/',
            },
          },
          {
            title: '결과 보기',
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      })
    }
  }
  return (
    <div className="kakao-share-button"    >
      {/* Kakao share button */}
      {/* <button id="kakao-link-btn"> */}
      <a style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 50px 5px 50px",
        textDecoration: "none",
        color: "#000"
      }}
        href="javascript:;" class="kakao-link-btn">
        <img
          style={{ width: "30px", height: "30px" }}
          src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
          alt="kakao-share-icon" />
          Kakao Talk 공유하기
      </a>
      {/* </button> */}
    </div>
  )
}
export default KakaoShare