import React from 'react';
import Axios from 'axios'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

// function preventDefault(event) {
//   event.preventDefault();
// }

// const useStyles = makeStyles({
//   depositContext: {
//     flex: 1,
//   },
// });

class Detail extends React.Component {
  state = {
    isLoadingImage: true,
    isLoadingMakeup: true,
    concealer: "#F35CC5",
    chip: "#F35CC5",
    shadow: "#F35CC5",
    lip: "#F35CC5"
  }
  makeupId = 1

  getImage = async () => {
    let id = window.location.hash.replace("#/dashboard/", "")
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/image/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      image: result,
      isLoadingImage: false
    })
    // console.log("getImage",result)
  }

  getMakeupList = async () => {
    const {
      data: {
        result
      }
    } = await Axios.get(`https://api.theblessedmoon.com/makeup/list`,
      {
        params: {
          pageNum: 0,
          pageCnt: 100
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.setState({
      makeup: result['data'],
      isLoadingMakeup: false
    })
    // console.log("getMakeupList",result[0].id)
  }

  patchImage = async (id, makeup, makeupId) => {
    // console.log("patchImage")
    await Axios.post(`https://api.theblessedmoon.com/image/${id}`, {
      "makeup": makeup,
      "makeupId": makeupId
    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  postProduct = async (id, concealer, chip, shadow, lip) => {
    // console.log("patchImage")
    await Axios.post(`https://api.theblessedmoon.com/product`, {
      "id": id,
      "concealer": concealer,
      "chip": chip,
      "shadow": shadow,
      "lip": lip
    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  postAligoTalk = async (id, receiver) => {
    // console.log("postAligoTalk")
    await Axios.post(`https://api.theblessedmoon.com/aligo/talk`, {
      "receiver": receiver,
      "id": id
    })
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  handleChange = event => {
    // console.log(event.target.name, event.target.value)
    this.makeupId = event.target.value
  };

  handleProductChange = event => {
    // console.log(event.target.name, event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleOnSend = (event) => {
    let id = window.location.hash.replace("#/dashboard/", "")
    let receiver = this.state.image.phone_number
    console.log(receiver)
    // console.log("kakao",this.state.image.kakao_id)
    this.postProduct(id, this.state.concealer, this.state.chip, this.state.shadow, this.state.lip)
    // this.patchImage(id, "makeup", this.makeupId)
    this.postAligoTalk(id, receiver)
    window.history.back()
  }

  componentDidMount() {
    this.getMakeupList()
    this.getImage()
  }

  render() {
    const {
      isLoadingMakeup, isLoadingImage, makeup, image
    } = this.state;
    return (
      <section className="container">
        {isLoadingImage || isLoadingMakeup ? (
          <div className="loader">
            <span className="loader_text">Loading...</span>
          </div>
        )
          : (
            <div style={{
              'display': "flex",
              'flex-direction': "column",
              'justify-content': "center",
              'align-items': "center",
              'padding':"5%"
            }}>
              <img src={image.url} alt="customer" style={{
                width: "300px",
                border: "2px solid rgba(255, 222, 222, 0.8)",
                margin: "5%"
              }} />
              <div style={{
                'display': "flex",
                'flex-direction': "column",
                'justify-content': "center",
                'align-items': "center",
              }}>
                <div>
                  <RadioGroup aria-label="블레스문 킷" defaultValue={`${makeup[0].id}`} name="블레스문 킷" onChange={this.handleChange}>
                    <FormLabel component="legend">블레스문 킷</FormLabel>
                    <div style={{
                      'display': "flex",
                      'flexWrap': "wrap"
                    }}>
                      {makeup.map(row => (
                        <FormControlLabel value={`${row.id}`} control={<Radio style={{ color: "#F35CC5" }} />} label={row.name} />
                      ))}
                    </div>
                  </RadioGroup>
                  <RadioGroup aria-label="컨실러" name="concealer" defaultValue="#F35CC5" onChange={this.handleProductChange}>
                    <FormLabel component="legend">컨실러</FormLabel>
                    <div style={{
                      'display': "flex",
                      'flexWrap': "wrap"
                    }}>
                      <FormControlLabel value="#F35CC5" control={<Radio style={{ color: "#F35CC5" }} />} label="#F35CC5" />
                      <FormControlLabel value="#123f2d" control={<Radio style={{ color: "#123f2d" }} />} label="#123f2d" />
                      <FormControlLabel value="#1FAcdd" control={<Radio style={{ color: "#1FAcdd" }} />} label="#1FAcdd" />
                    </div>
                  </RadioGroup>
                  <RadioGroup aria-label="칩" name="chip" defaultValue="#F35CC5" onChange={this.handleProductChange}>
                    <FormLabel component="legend">칩</FormLabel>
                    <div style={{
                      'display': "flex",
                      'flexWrap': "wrap"
                    }}>
                      <FormControlLabel value="#F35CC5" control={<Radio style={{ color: "#F35CC5" }} />} label="#F35CC5" />
                      <FormControlLabel value="#123f2d" control={<Radio style={{ color: "#123f2d" }} />} label="#123f2d" />
                      <FormControlLabel value="#1FAcdd" control={<Radio style={{ color: "#1FAcdd" }} />} label="#1FAcdd" />
                    </div>
                  </RadioGroup>
                  <RadioGroup aria-label="새도우" name="shadow" defaultValue="#F35CC5" onChange={this.handleProductChange}>
                    <FormLabel component="legend">새도우</FormLabel>
                    <div style={{
                      'display': "flex",
                      'flexWrap': "wrap"
                    }}>
                      <FormControlLabel value="#F35CC5" control={<Radio style={{ color: "#F35CC5" }} />} label="#F35CC5" />
                      <FormControlLabel value="#123f2d" control={<Radio style={{ color: "#123f2d" }} />} label="#123f2d" />
                      <FormControlLabel value="#1FAcdd" control={<Radio style={{ color: "#1FAcdd" }} />} label="#1FAcdd" />
                    </div>
                  </RadioGroup>
                  <RadioGroup aria-label="립" name="lip" defaultValue="#F35CC5" onChange={this.handleProductChange}>
                    <FormLabel component="legend">립</FormLabel>
                    <div style={{
                      'display': "flex",
                      'flexWrap': "wrap"
                    }}>
                      <FormControlLabel value="#F35CC5" control={<Radio style={{ color: "#F35CC5" }} />} label="#F35CC5" />
                      <FormControlLabel value="#123f2d" control={<Radio style={{ color: "#123f2d" }} />} label="#123f2d" />
                      <FormControlLabel value="#1FAcdd" control={<Radio style={{ color: "#1FAcdd" }} />} label="#1FAcdd" />
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <button onClick={this.handleOnSend} disabled={image.status === 'check' ? 'disabled' : false}>SEND</button>
            </div>
          )}
      </section>
    );
  }
}
export default Detail
