const users = [
  { id: "admin1", password: "admin" },
  { id: "admin2", password: "admin" },
  { id: "admin3", password: "admin" },
]

export function signIn({ id, password }) {
  const user = users.find((user) => user.id === id && user.password === password)
  if (user !== undefined) {
    var loginData = JSON.parse(window.localStorage.getItem('login'))

    if (loginData === null) {
      console.log("f")
      var time = new Date()
      var loginData = { "login": "login", "id": id, "password": password, "time": time.getTime() }
      window.localStorage.setItem('login', JSON.stringify(loginData))
      return
    }
  }

  if (user === undefined) throw new Error()
  return user
}