import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Image from "../image"
import styles from "./Mbti.module.css"

// import "./Mbti.module.css"
import mbtiKO from "../text/mbti/contents/mbtiKO.json"
import mbtiZH from "../text/mbti/contents/mbtiZH.json"
import mbtiJA from "../text/mbti/contents/mbtiJA.json"
import mbtiEN from "../text/mbti/contents/mbtiEN.json"

import titleKO from "../text/mbti/title/titleKO.json"
import titleZH from "../text/mbti/title/titleZH.json"
import titleJA from "../text/mbti/title/titleJA.json"
import titleEN from "../text/mbti/title/titleEN.json"

class Mbti extends React.Component {
  state = {
    mbti: mbtiEN,
    titlelang: titleEN,
    readMore: '... more',
    sliceNum: 100
  }

  componentDidMount = () => {
    const urlLang = document.location.hash.split('/')
    let mbti = ''
    let titlelang = ''
    switch (urlLang[1]) {
      case "en":
        mbti = mbtiEN
        titlelang = titleEN
        break
      case "ko":
        mbti = mbtiKO
        titlelang = titleKO
        break
      case "zh":
        mbti = mbtiZH
        titlelang = titleZH
        break
      case "ja":
        mbti = mbtiJA
        titlelang = titleJA
        break
    }

    this.setState({
      mbti: mbti,
      titlelang: titlelang
    })
  }

  handleChangeReadMore = () => {
    console.log(JSON.stringify(this.state.mbti))
    if (this.state.readMore === '... more') {
      this.setState({
        sliceNum: 1000,
        readMore: ' ...less'
      })
    }
    else {
      this.setState({
        sliceNum: 160,
        readMore: '... more'
      })
    }
  };

  render() {
    var myMbti = this.props.mbti
    var bestMbti = this.state.mbti[myMbti]['best']
    var worstMbti = this.state.mbti[myMbti]['worst']

    return (
      <div className={styles.container}>
        <p className={styles.tag} style={{ fontSize: "25px", width: "100%", textAlign: "center" }}>
          {this.state.titlelang.title}
        </p>
        <p className={styles.percent}>{this.state.mbti[myMbti]['percent']}</p>
        <pre style={{ textAlign: "center" }} className={styles.subtitle}>{this.state.mbti[myMbti]['title']}</pre>
        <p className={styles.mbti}>{myMbti.toUpperCase()}</p>
        <div>
          <img className={styles.mbit_image} src={this.state.mbti[myMbti]['image']} alt="sublogo" />
        </div>
        <table className={styles.table}>
          <tr>
            <td className={styles.td} width="25%">
              {this.state.titlelang.experience}
            </td>
            <td width="65%" >
              <div className={styles.progress}>
                <div className={styles.bar} style={{ width: `${this.state.mbti[myMbti]['experience']}%` }} />
              </div>
            </td>
            <td width="10%">{this.state.mbti[myMbti]['experience']}%</td>
          </tr>
          <tr>
            <td className={styles.td} width="25%">
              {this.state.titlelang.affection}
            </td>
            <td width="65%" >
              <div className={styles.progress}>
                <div className={styles.bar} style={{ width: `${this.state.mbti[myMbti]['affection']}%` }} />
              </div>
            </td>
            <td width="10%">{this.state.mbti[myMbti]['affection']}%</td>
          </tr>
          <tr>
            <td className={styles.td} width="25%">
              {this.state.titlelang.combat}
            </td>
            <td width="65%" >
              <div className={styles.progress}>
                <div className={styles.bar} style={{ width: `${this.state.mbti[myMbti]['combat']}%` }} />
              </div>
            </td>
            <td width="10%">{this.state.mbti[myMbti]['combat']}%</td>
          </tr>
          <tr>
            <td className={styles.td} width="25%">
              {this.state.titlelang.joke}
            </td>
            <td width="65%" >
              <div className={styles.progress}>
                <div className={styles.bar} style={{ width: `${this.state.mbti[myMbti]['joke']}%` }} />
              </div>
            </td>
            <td width="10%">{this.state.mbti[myMbti]['joke']}%</td>
          </tr>
          <tr>
            <td className={styles.td} width="25%">
              {this.state.mbti[myMbti]['characterName']}
            </td>
            <td width="65%" >
              <div className={styles.progress}>
                <div className={styles.bar} style={{ width: `${this.state.mbti[myMbti]['character']}%` }} />
              </div>
            </td>
            <td width="10%">{this.state.mbti[myMbti]['character']}%</td>
          </tr>
        </table>

        <div className={styles.contents}>
          {this.state.mbti[myMbti]['contents'].slice(0, this.state.sliceNum)}
          <span className={styles.black} onClick={this.handleChangeReadMore}>{this.state.readMore}</span>
        </div>
        <div className={styles.partner_maincontainer}>
          <Link className={styles.partner_container} to={{ pathname: `./${bestMbti}` }}>
            <div className={styles.partner_container_row}>
              <div className={styles.partner_left}>
                <p className={styles.partner_title}>{this.state.titlelang.best}</p>
                <p className={styles.partner_mbti}>{bestMbti.toUpperCase()}</p>
                <p className={styles.partner_contents}>{this.state.mbti[bestMbti]['title']}</p>
              </div>
              <div>
                <img className={styles.partner_image} src={this.state.mbti[bestMbti]['image']} alt="resultBest" />
              </div>
            </div>
          </Link>
          <Link className={styles.partner_container} to={{ pathname: `./${worstMbti}` }}>
            <div className={styles.partner_container_row}>
              <div className={styles.partner_left}>
                <p className={styles.partner_title}>{this.state.titlelang.worst}</p>
                <p className={styles.partner_mbti}>{worstMbti.toUpperCase()}</p>
                <p className={styles.partner_contents}>{this.state.mbti[worstMbti]['title']}</p>
              </div>
              <div>
                <img className={styles.partner_image} src={this.state.mbti[worstMbti]['image']} alt="resultWorst" />
              </div>
            </div>
          </Link>
        </div>

        <div className="result__consult-container">
          <div className="result__consult-container-row">
            <div>
              <img className="result__consult-image" src={this.state.mbti[myMbti]['makeup']} alt="consult" />
            </div>
            <div className="result__consult-right">
              <p className="result__consult-title">
                {this.state.titlelang.type}
                <span style={{ fontFamily: "DXMSubtitlesM-KSCpc-EUC-H", color: "#fc8493" }}> {this.state.titlelang.makeup} </span>
                {this.state.titlelang.tip}
              </p>
              <p className="result__consult-contents">{this.state.mbti[myMbti]['tip']}</p>
            </div>
          </div>
        </div>
      </div >
    )
  }
}


Mbti.propTypes = {
  mbti: PropTypes.string.isRequired
}

export default Mbti