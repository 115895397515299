import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Link, Switch, useHistory } from "react-router-dom";
import Image from "./image"
import "./App.css";

import { signIn } from './admin/login/auth';
import AuthRoute from './admin/login/AuthRoute';
import LoginForm from './admin/login/LoginForm';

import Navigation from "./components/navigation/Navigation";

import Intro from "./routes/intro/Intro";
import Test from "./routes/Test";
import Loading from "./routes/loading/Loading";
import Result from "./routes/Result";
import Upload from "./routes/Upload";
import Uploading from './routes/Uploading';
import Makeup from "./routes/Makeup";
import MakeupProduct from "./routes/MakeupProduct";
import Dashboard from './admin/dashboard/Dashboard';
import SignIn from './admin/login/SignIn';
import Footer from './components/footer/Footer';

let lang = navigator.language

function App() {
  const [user, setUser] = useState(null);
  const [shopLink, setLink] = useState("https://www.theblessedmoon.com/index.html");
  const history = useHistory()
  const authenticated = user != null;
  const login = ({ id, password }) => setUser(signIn({ id, password }));
  const logout = () => setUser(null);
  useEffect(() => {
    const urlLang = document.location.hash.split('/')

    if (document.location.hash == '#/') {
      if (lang.slice(0, 2) == "ko" || lang.slice(0, 2) == "en" || lang.slice(0, 2) == "ja" || lang.slice(0, 2) == "zh") {
        document.documentElement.lang = lang.slice(0, 2)
        onChangeLang(lang.slice(0, 2))
        document.location.hash = `${lang.slice(0, 2)}/intro`
      } else {
        document.documentElement.lang = 'en'
        document.location.hash = `en/intro`
        onChangeLang('en')
      }
    } else if (urlLang[1] == "ko" || urlLang[1] == "en" || urlLang[1] == "ja" || urlLang[1] == "zh") {
      document.documentElement.lang = urlLang[1]
      onChangeLang(urlLang[1])
    }
  }, []);

  const onChangeLang = (lang)=>{
    switch (lang) {
      case "en":
        setLink("https://www.amazon.com/stores/THE+BLESSEDMOON/page/7481A28F-1B2E-4474-B29A-91B7E4F6143F?ref_=ast_bln")
        break
      case "ko":
        setLink("https://www.theblessedmoon.com/index.html")
        break
      case "zh":
        setLink("https://search.kaola.com/brand/400367.html?spm=a2v0d.b11091998.0.0.12805953EIz9ON")
        break
      case "ja":
        setLink("https://www.qoo10.jp/shop/blessedmoon")
        break
    }
  }

  return (
    <>
      <HashRouter>
        {/* {authenticated ?<Navigation /> : <></>} */}
        <div className="app_container">
          {/* <Link  to = {authenticated ? "/dashboard" : "/intro"}> */}
          <a href={shopLink}><img className="app_logo" src={Image.top_logogray} alt="logo" /></a>
          {/* </Link> */}
        </div>
        <Route exact path="/" exact={true} component={Intro} />
        <Route path="/:lang/intro" exact={true} render={()=><Intro onChangeLang={onChangeLang}/>} />
        <Route path="/:lang/test" exact={true} component={Test} />
        <Route path="/:lang/loading" exact={true} component={Loading} />
        <Route path="/:lang/result/:mbti" exact={true} component={Result} />
        <Route path="/upload" exact={true} component={Upload} />
        <Route exact path="/uploading" exact={true} component={Uploading} />
        {/* <Route path="/makeup/:id" exact={true} component={Makeup} /> */}
        <Route path="/makeup/:id" exact={true} component={MakeupProduct} />
        <Switch>
          <Route
            path="/login"
            render={props => (
              <LoginForm authenticated={authenticated} login={login} {...props} />
            )}
          />
          <AuthRoute
            authenticated={authenticated}
            path="/dashboard"
            render={props => <Dashboard logout={logout} />}
          />
          <Route path="/dashboard/:id" exact={true} component={Dashboard} />
          <Route path="/signin" exact={true} component={SignIn} />
        </Switch>
      </HashRouter>
    </>
  )
}

export default App;