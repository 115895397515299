import React, { useState } from 'react';
import "./Loading.css"
import Image from "../image.js"
import Axios from 'axios';
import { storage } from '../firebase'
import { v4 as uuidv4 } from 'uuid';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sendButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    borderRadius: '30px',
    width: '100%',
    maxWidth: '340px',
    fontSize: '33px',
    marginTop: '5%',
    cursor: 'pointer',
    backgroundColor: '#202020',
    color: '#FFF',
    textDecoration: 'none',
  }
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Uploading() {
  const [open, setOpen] = useState(false);
  const [sendCheck, setSendCheck] = useState(false);
  const [upload, setUpload] = useState(true);
  const [number, setNumber] = useState();
  let timer

  const handleCheckClose = () => {
    window.location.href = `#/ko/intro`
    sessionStorage.setItem("uploadCheck", true);
    setSendCheck(false);
  };
  const handleSendClose = () => {
    let id = window.sessionStorage.getItem("uploadId")
    patchImage(id, number);
    setOpen(false);
  };
  const postImage = async (url) => {
    await Axios.post('https://api.theblessedmoon.com/image', {
      "url": url
    }).then(function (response) {
      if (response.data.code >= 200) {
        alert("네트워크 연결 오류입니다.")
        window.location.href = `#/upload`
        clearTimeout(timer)
      }
      window.sessionStorage.setItem('firebaseUrl', response.data.result.url)
      window.sessionStorage.setItem('uploadId', response.data.result.id)
    })
      .catch(function (error) {
      });
  }

  //이미지 상태값 조회
  const getStatus = async () => {
    let id = window.sessionStorage.getItem("uploadId")

    await Axios.get(`https://api.theblessedmoon.com/image/${id}/status`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(function (response) {
        if (response.data.result.status == "check") {
          window.location.href = `#/makeup/${id}`
        } else {
          setOpen(true)
        }
      })
      .catch(function (error) {
      });
  }
  // 핸드폰 추가
  const patchImage = async (id, phoneNumber) => {
    await Axios.post(`https://api.theblessedmoon.com/image/${id}`, {
      "phoneNumber": phoneNumber
    })
      .then(function (response) {
        setSendCheck(true)
      })
      .catch(function (error) {
      });
  }

  //이미지 업로드
  const handleUpload = async (url) => {
    let attachmentUrl = "";

    if (url == null) {
      alert("네트워크 연결 오류입니다.")
      window.location.href = `#/upload`
      clearTimeout(timer)
      return;
    } else {
      const attachmentRef = storage.ref().child(`images/${uuidv4()}`);
      const response = await attachmentRef.putString(url, "data_url");
      attachmentUrl = await response.ref.getDownloadURL();
      postImage(attachmentUrl)
    }
  };

  //핸드폰 입력
  const handlePhoneNumber = (event) => {
    event.target.value = event.target.value.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    setNumber(event.target.value)
    console.log(number)
  }

  let base64Url = window.sessionStorage.getItem("base64Url");

  if (upload) {
    console.log('upload')
    setUpload(false)
    if (sessionStorage.getItem("uploadCheck") != 'true') {
      handleUpload(base64Url);
      timer = setTimeout(() => getStatus(), 4000);
    }
  }

  return (
    <div>
      <div className="loading__container">
        <div>
          <img className="loading__logosub" src={Image.loading} alt="sublogo" />
        </div>
        <div>
          <p style={{ fontSize: '25px', margin: '20px' }}>메이크업 추천중...</p>
        </div>
      </div>
      <Dialog onClose={handleSendClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img style={{ width: "70%" }} src={Image.dialog}></img>
          </div>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <TextField
              autoFocus
              margin="dense"
              id="number"
              label="Phone Number"
              type="tel"
              fullWidth
              onChange={handlePhoneNumber}
              style={{ width: "70%" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Button
              style={{
                height: '50px',
                borderRadius: '30px',
                width: '150px',
                fontSize: '150%',
                cursor: 'pointer',
                backgroundColor: '#202020',
                color: '#FFF',
                textDecoration: 'none',
              }} onClick={handleSendClose} color="primary">
              SEND
          </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog onClose={handleCheckClose} aria-labelledby="customized-dialog-title" open={sendCheck}>
        <DialogContent dividers>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img style={{ width: "70%" }} src={Image.dialog_2}></img>
          </div>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Button
              style={{
                height: '50px',
                borderRadius: '30px',
                width: '150px',
                fontSize: '150%',
                cursor: 'pointer',
                backgroundColor: '#f7b7bf',
                color: '#FFF',
                textDecoration: 'none',
                boxShadow: 'none',
                textTransform: 'none',
              }} onClick={handleCheckClose}>
              Finish
          </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}