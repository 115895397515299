const image = {
  top_logo: require('./image/logo.png'),
  top_logogray: require('./image/logo_gray.png'),
  intro_logo: require('./image/intro_logo.gif'),
  loading: require('./image/loading.gif'),
  resultINFJ: require('./image/layer-6.jpg'),
  resultBest: require('./image/layer-8.png'),
  resultWorst: require('./image/layer-7.png'),
  consult: require('./image/layer-9.png'),
  insta: require('./image/instagram.png'),
  makeup_after: require('./image/makeup_after.png'),
  makeup_before: require('./image/makeup_before.png'),
  makeup_img: require('./image/makeup_img.png'),
  product: require('./image/product.png'),
  sns_arrow: require('./image/sns_arrow.png'),
  sns_x: require('./image/sns_x.png'),
  sns_title: require('./image/make-your-own-kit-copy.png'),
  kakao: require('./image/kakao.png'),
  dialog: require('./image/dialog.png'),
  dialog_2: require('./image/dialog_2.png'),
  uploadpage: require('./image/upload_page.jpg'),
  backButton: require('./image/뒤로가기.png'),
  homeButton: require('./image/처음으로 돌아가기.png'),
  
  product_case: require('./image/product/case.png'),
  product_color_1_1: require('./image/product/1_1.png'),
  product_color_1_2: require('./image/product/1_2.png'),
  product_color_1_3: require('./image/product/1_3.png'),
  product_color_1_4: require('./image/product/1_4.png'),
  product_color_2_1: require('./image/product/2_1.png'),
  product_color_2_2: require('./image/product/2_2.png'),
  product_color_2_3: require('./image/product/2_3.png'),
  product_color_2_4: require('./image/product/2_4.png'),
  product_color_3_1: require('./image/product/3_1.png'),
  product_color_3_2: require('./image/product/3_2.png'),
  product_color_3_3: require('./image/product/3_3.png'),
  product_color_3_4: require('./image/product/3_4.png'),
  product_color_4_1: require('./image/product/4_1.png'),
  product_color_4_2: require('./image/product/4_2.png'),
  product_color_4_3: require('./image/product/4_3.png'),
  product_color_4_4: require('./image/product/4_4.png'),

  individual_color_1_1: require('./image/product/individual/1_1.png'),
  individual_color_1_2: require('./image/product/individual/1_2.png'),
  individual_color_1_3: require('./image/product/individual/1_3.png'),
  individual_color_1_4: require('./image/product/individual/1_4.png'),
  individual_color_2_1: require('./image/product/individual/2_1.png'),
  individual_color_2_2: require('./image/product/individual/2_2.png'),
  individual_color_2_3: require('./image/product/individual/2_3.png'),
  individual_color_2_4: require('./image/product/individual/2_4.png'),
  individual_color_3_1: require('./image/product/individual/3_1.png'),
  individual_color_3_2: require('./image/product/individual/3_2.png'),
  individual_color_3_3: require('./image/product/individual/3_3.png'),
  individual_color_3_4: require('./image/product/individual/3_4.png'),
  individual_color_4_1: require('./image/product/individual/4_1.png'),
  individual_color_4_2: require('./image/product/individual/4_2.png'),
  individual_color_4_3: require('./image/product/individual/4_3.png'),
  individual_color_4_4: require('./image/product/individual/4_4.png'),

  shadow_1: require('./image/product/shadow/1.png'),
  tint_1: require('./image/product/tint/1.png'),

  makeup_estj: require('./image/mbti_makeup/ESTJ.png'),
  makeup_entp: require('./image/mbti_makeup/ENTP.png'),
  makeup_enfj: require('./image/mbti_makeup/ENFJ.png'),
  makeup_enfp: require('./image/mbti_makeup/ENFP.png'),
  makeup_entj: require('./image/mbti_makeup/ENTJ.png'),
  makeup_infp: require('./image/mbti_makeup/INFP.png'),
  makeup_intp: require('./image/mbti_makeup/INTP.png'),
  makeup_infj: require('./image/mbti_makeup/INFJ.png'),
  makeup_esfj: require('./image/mbti_makeup/ESFJ.png'),
  makeup_esfp: require('./image/mbti_makeup/ESFP.png'),
  makeup_estp: require('./image/mbti_makeup/ESTP.png'),
  makeup_intj: require('./image/mbti_makeup/INTJ.png'),
  makeup_isfj: require('./image/mbti_makeup/ISFJ.png'),
  makeup_istp: require('./image/mbti_makeup/ISTP.png'),
  makeup_istj: require('./image/mbti_makeup/ISTJ.png'),
  makeup_isfp: require('./image/mbti_makeup/ISFP.png'),

  mbti_estj: require('./image/mbti_character/estj.png'),
  mbti_entp: require('./image/mbti_character/entp.png'),
  mbti_enfj: require('./image/mbti_character/enfj.png'),
  mbti_enfp: require('./image/mbti_character/enfp.png'),
  mbti_entj: require('./image/mbti_character/entj.png'),
  mbti_infp: require('./image/mbti_character/infp.png'),
  mbti_intp: require('./image/mbti_character/intp.png'),
  mbti_infj: require('./image/mbti_character/infj.png'),
  mbti_esfj: require('./image/mbti_character/esfj.png'),
  mbti_esfp: require('./image/mbti_character/esfp.png'),
  mbti_estp: require('./image/mbti_character/estp.png'),
  mbti_intj: require('./image/mbti_character/intj.png'),
  mbti_isfj: require('./image/mbti_character/isfj.png'),
  mbti_istp: require('./image/mbti_character/istp.png'),
  mbti_istj: require('./image/mbti_character/istj.png'),
  mbti_isfp: require('./image/mbti_character/isfp.png'),
}


export default image
