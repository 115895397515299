import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import FaceIcon from '@material-ui/icons/Face';
import StyleIcon from '@material-ui/icons/Style';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <div >
    <Link to="/dashboard/orders" style={{ textDecoration: "none", color: "#000000" }}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItem>
    </Link>
    {/* <Link to="/dashboard/makeup" style={{ textDecoration: "none", color: "#000000" }}>
      <ListItem button>
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText primary="Makeup" />
      </ListItem>
    </Link>
    <Link to="/dashboard/product" style={{ textDecoration: "none", color: "#000000" }}>
      <ListItem button>
        <ListItemIcon>
          <StyleIcon />
        </ListItemIcon>
        <ListItemText primary="Product" />
      </ListItem>
    </Link> */}
  </div>
);
