import React from 'react';
import Image from "../image.js"
import "./Upload.css"
import Resizer from 'react-image-file-resizer';

const Upload = () => {
  sessionStorage.removeItem("uploadCheck")
  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });

  const handleChange = async (e) => {
    const image = e.target.files[0];
    const base64Url = await resizeFile(image);
    window.sessionStorage.setItem('base64Url', base64Url)
    window.location.href = `#/uploading`
  };


  return (
    <div className="upload__container">
      <label for="ex_file">
        <img className="upload__background" src={Image.uploadpage} alt="uploadpage" />
      </label>
      <div class="filebox">
        <input type="file" accept="image/*" id="ex_file" onChange={handleChange} />
      </div>
    </div>
  )
}
export default Upload;