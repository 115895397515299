import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Image from "../../image"
import styles from "./Loading.module.css"
import mainKO from "../../text/main/mainKO.json"
import mainZH from "../../text/main/mainZH.json"
import mainJA from "../../text/main/mainJA.json"
import mainEN from "../../text/main/mainEN.json"

const Loading = (props) => {
  const [lang, setLang] = useState('')
  const urlLang = document.location.hash.split('/')
  const history = useHistory()
  const testResult = window.sessionStorage.getItem("testResult")

  //Mbti 판단
  const checkMBTI = (testResult) => {
    const quesionMbti = [
      ["t", "p", "f", "i", "s", "e", "n", "p", "f", "n", "e", "j"],
      ["f", "j", "t", "e", "n", "i", "s", "j", "t", "s", "i", "p"]
    ];

    const result = {
      'e': 0, 'i': 0, 'n': 0, 's': 0, 'f': 0, 't': 0, 'j': 0, 'p': 0
    };

    let myMbti = ''

    if (testResult == null) {
      setTimeout(() => history.push(`/intro`), 4000);
    }
    else {
      for (let i = 0; i < 12; i++) {
        const checkResult = quesionMbti[testResult[i * 2]][i]
        result[checkResult] = (result[checkResult]) + 1 || 1
      }

      result['e'] > result['i'] ? myMbti = 'e' : myMbti = 'i'
      result['n'] > result['s'] ? myMbti = myMbti + 'n' : myMbti = myMbti + 's';
      result['f'] > result['t'] ? myMbti = myMbti + 'f' : myMbti = myMbti + 't';
      result['j'] > result['p'] ? myMbti = myMbti + 'j' : myMbti = myMbti + 'p';

      setTimeout(() => history.push(`./result/${myMbti}`), 4000);
    }
    return;
  }

  useEffect(() => {
    checkMBTI(testResult)
    switch (urlLang[1]) {
      case "en":
        setLang(mainEN)
        break
      case "ko":
        setLang(mainKO)
        break
      case "zh":
        setLang(mainZH)
        break
      case "ja":
        setLang(mainJA)
        break
    }
  }, [])

  return (
    <div className={styles.container}>
      <div>
        <img className={styles.sublogo} src={Image.loading} alt="sublogo" />
      </div>
      <div>
        <p style={{ fontSize: '25px', margin: '20px' }}>{lang.loading}</p>
      </div>
    </div>
  )
};

export default Loading;