import React from 'react';
import Image from "../image.js"
import { Link } from 'react-router-dom';
import Mbti from '../components/Mbti';
import "./Result.css"

import titleKO from "../text/mbti/title/titleKO.json"
import titleZH from "../text/mbti/title/titleZH.json"
import titleJA from "../text/mbti/title/titleJA.json"
import titleEN from "../text/mbti/title/titleEN.json"

class Result extends React.Component {
  state = {
    titlelang: titleEN,
  }
  componentDidMount = () => {
    const urlLang = document.location.hash.split('/')
    let titlelang = ''
    switch (urlLang[1]) {
      case "en":
        titlelang = titleEN
        break
      case "ko":
        titlelang = titleKO
        break
      case "zh":
        titlelang = titleZH
        break
      case "ja":
        titlelang = titleJA
        break
    }

    this.setState({
      titlelang: titlelang
    })
  }

  render() {
    return (
      <div className="result__container">
        <div>
          <Mbti mbti={this.props.match.params.mbti} />
        </div>
        <div className="result__consult-container-row">
          <a className="result__shop" href="https://theblessedmoon.com/index.html">{
            document.documentElement.lang == "ko" ? `SHOP NOW` : `KOREA SHOP`
          }</a>
          {
            document.documentElement.lang == "ko" ?
              <Link className="result__consult" to="/upload">{this.state.titlelang.shop}</Link> :
              <a className="result__consult" href={this.state.titlelang.link}>{this.state.titlelang.shop}</a>
          }
        </div>
        <a className="a" href="http://www.instagram.com/blessedmoon_cosmetics">
          <img className="result__insta" src={Image.insta} alt="insta" />
          <div className="result__insta-text" >{this.state.titlelang.insta}</div>
        </a>
      </div>
    )
  }
}
export default Result;